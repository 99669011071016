import { useState } from 'react';
import {useLocation, useHistory} from "react-router-dom";
import { useStyletron } from 'styletron-react';
import { useConnection } from './hooks/use-connection';
import {Controller} from './Controller'

function App() {
  const {isReady, error, sendMessage, connect} = useConnection()
  const location = useLocation();
  const history = useHistory();
  const [code, setCode] = useState(location.hash.slice(1));
  const [css] = useStyletron();
  function onClick(e) {
    if (e.target.id) {

      sendMessage({
        isPressed: e.type === 'mousedown' || e.type === "touchstart",
        key: e.target.id
      })
    }
  }

  function onConnect(){
    history.push(`#${code}`)
    connect(code)
  }

  return (
    isReady ? <Controller onClick={onClick}/> :
    <div className={css({
      textAlign: 'center',
      margin: '20px'
    })}>
      <h2>{'Welcome to GameBoy.tv'}</h2>
      <p>
        {"This is a web-based controller for a RetroPie emulator."}
      </p>
      <p>
        {"If you weren't specifically sent here by someone who gave you a special device, it won't work for you."}
      </p>
      <label>
        <div>{'Enter the code on your screen:'}</div>
        <input value={code} onChange={e => setCode(`${e.target.value}`)}></input>
      </label>
      {error ? <div className={css({color: 'red'})}>{"Error connecting, check the code and try again"}</div> : null}
  
      <div>
      <button onClick={onConnect}>
        connect
      </button>

      </div>
    </div>
  );
}

export default App;
