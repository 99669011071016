import React, { createContext, useContext, useRef, useState } from "react";
const ConnectContext = createContext({
    isReady: false,
    lastMessage: null,
    sendMessage: () => {},
    connect: () => {}
});

export function useConnection(id) {
    return useContext(ConnectContext)
}

function useSocket(){
    const socket = useRef(null)
    const [isReady, setIsReady] = useState(false)
    const [error, setError] = useState(null)
    const [lastMessage, setMessage] = useState(null)

    function setSocket(s) {
        socket.current = s;
        socket.current.onclose = () => setIsReady(false);
        socket.current.onerror = (err) => {
            setError(err);
            setIsReady(false);
        }
        socket.current.onopen = () => setIsReady(true);
        socket.current.onmessage = (msg) => {
            setMessage(JSON.parse(msg));
        };
    }

    function sendMessage(msg) {
        console.log("sendMessage", msg)
        if (socket.current) {
            socket.current.send(JSON.stringify(msg))
        }
    }

    const webSocket = {
        error,
        isReady,
        lastMessage,
        sendMessage
    };

    return [webSocket, setSocket];
}

export const Provider = ({ children, contracts }: ProviderProps) => {
    const [socket, setSocket] = useSocket()
    function connect(id) {
        console.log("connect initiated")
        const ws = new WebSocket(`wss://${id}.ngrok.io/controller`)
        setSocket(ws)
    }

    console.log("socket", socket)

    return (
      <ConnectContext.Provider value={{
          ...socket,
          connect
      }}>
        {children}
      </ConnectContext.Provider>
    );
  };