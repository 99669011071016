import { useStyletron } from 'styletron-react';
import controllerImg from './img/controller.png';

export function Controller({onClick}) {
  const [css] = useStyletron();

  const controllerStyle = css({
    position: 'fixed',
    bottom: 0,
    overflow: 'auto',
    width: '100%',
    opacity: 0.5,
    userSelect: 'none'
  })

  return (
    <div className={css({
      width: "100vh",
      height: "100vh",
      userSelect: 'none'
    })}>
      <img src={controllerImg} className={controllerStyle} alt="logo" />
      <svg className={controllerStyle} onMouseDown={onClick} onMouseUp={onClick} onTouchStart={onClick} onTouchEnd={onClick} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2048 888">
        <svg className="controls" xmlns="http://www.w3.org/2000/svg" version="1.1"  width="2048" height="2048" preserveAspectRatio="xMinYMin">
          <g>
            <polygon id='left' points="0,0 0,888 430,447" pointerEvents="visible" fill="transparent" />
            <polygon id='up' points="0,0 888,0 430,447" pointerEvents="visible" fill="transparent" />
            <polygon id='down' points="0,888 888,888 430,447" pointerEvents="visible" fill="transparent" />
            <polygon id='right' points="888,888 700,447 888,0 430,447" pointerEvents="visible" fill="transparent" />

            <polygon id='select' points="780,300 1080,300 900,700 780,700" pointerEvents="visible" fill="transparent" />
            <polygon id='start' points="1280,300 1080,300 900,700 1280,700" pointerEvents="visible" fill="transparent" />

            <polygon id='b' points="1280,0 1280,888 2000,888" pointerEvents="visible" fill="transparent" />
            <polygon id='a' points="1280,0 2000,0 2000,888" pointerEvents="visible" fill="transparent" />
          </g>
        </svg>
      </svg>
    </div>
  );
}
